.ranAnimatedButton {
  position: relative;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  border-radius: 999px;
  background: #26316d;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
}

.ranAnimatedButton .hover-btn {
  position: absolute;
  inset: 0;
  background: linear-gradient(0deg, #e71e25 0%, #f4b746 100%);
  transform: translate(0%, 100%);
}

.ranAnimatedButton:hover .default-btn {
  transform: translate(0%, -100%);
}

.ranAnimatedButton:hover .hover-btn {
  transform: translate(0%, 0%);
}
