/* nav item styles */
.nav-item span {
  position: relative;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
}

.nav-item span:focus:after,
.nav-item span:hover:after {
  width: 100%;
  left: 0%;
}

.nav-item span:after {
  content: "";
  pointer-events: none;
  bottom: -5px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #fff;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}

/* up and down animations for all elements */
.hero_img_animation {
  position: relative;
  z-index: 3;
  animation: up_down_animation 3s ease-in-out infinite;
}

.hero_img_animation2 {
  position: relative;
  z-index: 3;
  animation: up_down_animation 5s ease-in-out infinite;
}

.up_down_animation {
  position: relative;
  animation: up_down_animation 5s ease-in-out infinite;
}

.up_down_animation2 {
  position: relative;
  animation: up_down_animation 3s ease-in-out infinite;
}

.bottom_circle_shadow {
}
.bottom_circle_shadow::after {
  content: " ";
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 30px;
  border-radius: 999px;
  background: radial-gradient(50% 50% at 50% 50%, #00000069 0%, #00000000 100%);
  z-index: 1;
}

@keyframes up_down_animation {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 640px) {
  .hero_img_animation2::after,
  .hero_img_animation::after {
    bottom: -10px;
    left: 50%;
    width: 50%;
    height: 20px;
  }
  @keyframes up_down_animation {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}
