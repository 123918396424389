@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Wicked Mouse";
  src: url("./assets/fonts/WickedMouse/158e715d2cf42256101d89a4e7bc909e.eot");
  src: url("./assets/fonts/WickedMouse/158e715d2cf42256101d89a4e7bc909e.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/WickedMouse/158e715d2cf42256101d89a4e7bc909e.woff2")
      format("woff2"),
    url("./assets/fonts/WickedMouse/158e715d2cf42256101d89a4e7bc909e.woff")
      format("woff"),
    url("./assets/fonts/WickedMouse/158e715d2cf42256101d89a4e7bc909e.ttf")
      format("truetype"),
    url("./assets/fonts/WickedMouse/158e715d2cf42256101d89a4e7bc909e.svg#Wicked Mouse")
      format("svg");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fc7e43;
  color: #26316d;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
